import type { Address } from 'abitype'

import type { ByteArray, Hex } from '../../types/misc.js'
import { getAddress } from '../address/getAddress.js'
import { isAddressEqual } from '../address/isAddressEqual.js'

import { recoverMessageAddress } from './recoverMessageAddress.js'

export type VerifyMessageParameters = {
  /** The address that signed the original message. */
  address: Address
  /** The message to be verified. */
  message: string
  /** The signature that was generated by signing the message with the address's private key. */
  signature: Hex | ByteArray
}

export type VerifyMessageReturnType = boolean

/**
 * Verify that a message was signed by the provided address.
 *
 * Note:  Only supports Externally Owned Accounts. Does not support Contract Accounts.
 *        It is highly recommended to use `publicClient.verifyMessage` instead to ensure
 *        wallet interoperability.
 *
 * - Docs {@link https://viem.sh/docs/utilities/verifyMessage.html}
 *
 * @param parameters - {@link VerifyMessageParameters}
 * @returns Whether or not the signature is valid. {@link VerifyMessageReturnType}
 */
export async function verifyMessage({
  address,
  message,
  signature,
}: VerifyMessageParameters): Promise<VerifyMessageReturnType> {
  return isAddressEqual(
    getAddress(address),
    await recoverMessageAddress({ message, signature }),
  )
}
